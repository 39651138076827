import { createContext, useContext, useState, useEffect } from "react"
import { fireAuth, firestoreDB } from "./FirebaseGlobals"
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { getDoc, setDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";

const authContext = createContext(null)
const { Provider } = authContext

export function AuthProvider(props) {
  const auth = userAuthProvider()
  return <Provider value={auth}>{props.children}</Provider>
}

export const userAuth = () => {
  return useContext(authContext)
}

const userAuthProvider = () => {
  const [user, setUser] = useState(false)
  const [isAdmin, setAdmin] = useState(false)
  const [roles, setRoles] = useState([])

  useEffect(() => {
    const unsub = onAuthStateChanged(fireAuth, handleAuthState)
    return () => unsub()
  }, [])


  const login = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(fireAuth, provider)
      .then((result) => {
        addUserToFirestore(result);
      })
      .catch((error) => {
        console.log(error);
        console.log("failed");
      });
  };

  const handleAuthState = user => {
    setUser(user)
    if (user) {
      fetchUserDataFromFirestore(user)
    }
  }

  const addUserToFirestore = async (result) => {
    // if (!getAdditionalUserInfo(result).isNewUser) return; TODO uncomment when all updated
    const { displayName, email, photoURL, uid } = result.user;
    const docRef = doc(firestoreDB, "users", uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      updateDoc(docRef, { uid, displayName, email, photoURL });
    }
    else {
      setDoc(docRef, { uid, displayName, email, photoURL });
    }
  };

  const fetchUserDataFromFirestore = async (user) => {
    const docSnap = await getDoc(doc(firestoreDB, "users", user.uid))

    if (docSnap.exists()) {
      setUser(docSnap.data())
      setRoles(docSnap.data().roles)
      setAdmin(docSnap.data().roles?.includes('admin'))
    }
  }


  const logout = () => {
    console.log("logged out");
    return signOut(fireAuth).then(() => setUser(false))
  };

  const register = async (name, email, password) => {
    console.log("register function called");
    await createUserWithEmailAndPassword(email, password);
    return fireAuth.currentUser.updateProfile({
      displayName: name
    });
  };

  const setUserRole = async (uid, role = 'approved', remove = false) => {
    if (isAdmin) {
      var roles = [];
      const docRef = doc(firestoreDB, "users", uid)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        var user = docSnap.data();
        roles = user.roles ? user.roles : []

        if (remove) {
          var index = roles.indexOf(role)
          roles.splice(index, 1);
        }
        else {
          roles.push(role)
        }
        updateDoc(docRef, { roles: roles });
      }
    }
  }

  const canOpen = () => {
    return roles !== undefined && (roles.includes('approved') || roles.includes('admin') || roles.includes('gatekeeper'))
  }

  return { user, register, login, logout, isAdmin, setUserRole, canOpen }
}
