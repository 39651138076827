import React, { useState } from 'react';
import axios from 'axios';
import { userAuth } from "../configs/Firebase";
import { generatePath } from "react-router";

const developerkey = process.env.REACT_APP_REMOT3_API_KEY;

function ButtonDisplay() {
  const uAuth = userAuth();
  const [visible, setVisible] = useState(true);

  return (
    <div>
      <div>
        {uAuth.canOpen() ? <button onClick={() => { setVisible(false); SendSesame() }} className="button">
          <span className="buttonGate">Open the TikiBar !</span>
          <br />
          {visible ? <img src="img/gate.png" alt="gate icon" className="icon logo" /> : <img src="img/gate_opening.png" alt="gate icon" className="icon logo" />}
        </button> : 'You are registered but not approved yet'}

        <button onClick={uAuth.logout} className="button">
          Log Out
        </button>
        {uAuth.isAdmin ? <a href={generatePath('/users')} className="button">
          Users
        </a> : ''}
      </div>
    </div>
  );
}

function SendSesame() {
  const apiKey = process.env.REACT_APP_SESAME_API_KEY;
  const address = 'https://drkicksontiki.loca.lt/'

  if (process.env.REACT_APP_ENV === undefined)
    return console.log('Faking the opening of the door')

  axios.get(
    address + '/sesame',
    {
      headers: {
        'Api-Key': apiKey,
        'Bypass-Tunnel-Reminder': 'true'
      }
    }
  )
    .then(response => {
      console.log("Body:", 'Welcome!');
    })
    .catch(error => {
      alert('Failed to connect to the door:' + error)
    });
}

export default ButtonDisplay;
