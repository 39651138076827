import { initializeApp, getApp } from "firebase/app"
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseAPIKey = process.env.REACT_APP_FIREBASE_API_KEY;

const firebaseApp = initializeApp({
  apiKey: firebaseAPIKey,
  authDomain: "tikitest-bc53f.firebaseapp.com",
  projectId: "tikitest-bc53f",
  storageBucket: "tikitest-bc53f.appspot.com",
  messagingSenderId: "101790853292",
  appId: "1:101790853292:web:bc80918f83f6cb9196d709"
});

const app = getApp();
const fireAuth = getAuth(firebaseApp);
const firestoreDB = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { firebaseApp, app, fireAuth, firestoreDB, storage };
