import "./styles.scss";
import "./button.scss";
import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AuthProvider, userAuth } from "./configs/Firebase";
import { PrivateRoute } from "./components/PrivateRoute"
// import { UrlProvider } from "./components/RemoteIt"
import ButtonDisplay from "./components/Gate";

const GoogleButtons = lazy(() => import("./components/GoogleButtons"))
const Gate = lazy(() => import("./components/Gate"))
const DisplayUsers = lazy(() => import("./components/Users"))

export default function App() {
  const uAuth = userAuth();

  return (
    <AuthProvider>
      <Router>
        <div>
          <Routes>
            <Route element={<PrivateRoute />} path="/gate">
              <Route element={<ButtonDisplay />} />
            </Route>
            <Route path="/users" element={<DisplayUsers />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

function Home() {
  const uAuth = userAuth();


  return (
    <div className="App">
      <img src="img/dr_kick_logo.jpg" alt="gate icon" className="icon logo"></img>
      <h1>Welcome {uAuth.user ? uAuth.user.displayName : '!'}</h1>
      <div id="Main">
        <h2>Bring your BAE game!</h2>
        <Suspense fallback={<div>Loading...</div>}>
          {!uAuth.user ? <GoogleButtons /> : <Gate />}
        </Suspense>
      </div>
    </div>
  );
}
