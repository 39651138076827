import { BrowserRouter as Route, Redirect } from "react-router-dom";
import { userAuth } from "../configs/Firebase";

export function PrivateRoute({ children, ...rest }) {
  const uAuth = userAuth();

  if (!user) {
    return <Navigate to={'/'} replace />;
  }

  return <Outlet />;
};


